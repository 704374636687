import * as React from 'react'
import Layout from '../../components/layout'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'



const Tag = ({ pageContext, data }) => {
  
  console.log("page context: ")
  console.log(pageContext)  
  console.log(data)

  return (
    <Layout pageTitle={pageContext.tag}>
      <p>Tag Name: {pageContext.tag}</p>
      <p>Count: {pageContext.count}</p>
    </Layout>
  )
}

export const query = graphql`
    query {  
    allMdx(filter: {}) {
      group(field: frontmatter___tags) {
        totalCount
        fieldValue
      }
    }
  }
`

export default Tag